<template>
  <div class="radio-group">
    <label v-if="label" class="radio-group-label">
      {{ label }}
      <info v-if="tooltip" :tooltip="tooltip" />
    </label>
    <div>
      <radio-gcr
        v-for="option in options"
        :group="group"
        :inline="inline"
        :key="option.value"
        :label="option.label"
        :value="option.value"
        :disabled="option.disabled"
        :validation-feedback="validationFeedback"
        v-model="modelValue" />
      <div v-if="validationFeedbackVisible" class="invalid-feedback">{{ validationFeedback }}</div>
    </div>
  </div>
</template>

<script>
import RadioGcr from '@/components/atoms/Radio'
import Info from '@/components/atoms/Info'

export default {
  components: { RadioGcr, Info },
  props: {
    label: {},
    tooltip: {},
    group: {},
    value: {},
    options: {},
    inline: {
      type: Boolean,
      default: () => false
    },
    validationFeedback: {
      type: String,
      default: () => ''
    },
    showValidationFeedback: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    validationFeedbackVisible() {
      return this.showValidationFeedback && this.validationFeedback
    }
  }
}
</script>

<style scoped>
.invalid-feedback {
  display: none;
}

.was-validated .invalid-feedback {
  display: block;
}
</style>
