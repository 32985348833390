export default {
  methods: {
    change() {
      this.dirty = true
    }
  },
  computed: {
    dirty: {
      get() {
        return this.$store.state.form.isDirty
      },
      set(value) {
        this.$store.commit('form/setDirty', value)
      }
    }
  },
  mounted() {
    this.$el.addEventListener('change', this.change)
  },
  beforeDestroy() {
    this.$el.removeEventListener('change', this.change)
  }
}
