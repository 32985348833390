<template>
  <div class="a-cash">
    <label v-if="label" :for="id">
      {{ label }}
      <info v-if="tooltip" :tooltip="tooltip" @click.native.prevent />
    </label>
    <currency-input
      class="form-control"
      v-model="price"
      v-bind="$attrs"
      :id="id"
      :precision="0"
      :currency="{suffix: ' $'}"
      locale="fr-CA"
      :allow-negative="false"
    />
    <div class="invalid-feedback html5-validation">{{ validationFeedback }}</div>
  </div>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input'
import Info from '@/components/atoms/Info'

export default {
  components: { CurrencyInput, Info },
  inheritAttrs: false,
  data() {
    return {}
  },
  props: {
    label: {},
    tooltip: {},
    value: {},
    id: {
      default() {
        return `input-${this._uid}`
      }
    },
    validationFeedback: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    price: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    input() {
      return this.$el.querySelector('input')
    }
  },
  watch: {
    validationFeedback(message) {
      this.input().setCustomValidity(message)
    }
  },
  mounted() {
    this.input().setCustomValidity(this.validationFeedback)
  }

}
</script>

<style lang="scss">
@import '../../utilities/config';

.a-cash {
  input {
    &::placeholder {
      color: gray('gray-150');
      font-weight: 300;
    }
  }
}
</style>
